import { useEffect, useState } from "react";

export default function useGoogleAnalytics(id, enableTracking, cookiesAllowed) {
  const [gaInitialized, setGaInitialized] = useState(false);

  useEffect(() => {
    if (id && enableTracking && !gaInitialized) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;

      document.head.appendChild(script);

      const dataLayerPush = document.createElement("script");
      dataLayerPush.type = "text/javascript";
      const code = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${id}');
      `;

      try {
        dataLayerPush.appendChild(document.createTextNode(code));
        document.body.appendChild(dataLayerPush);
      } catch (e) {
        dataLayerPush.text = code;
        document.body.appendChild(dataLayerPush);
      }

      setGaInitialized(true);
    }
  }, [id, enableTracking, cookiesAllowed, gaInitialized]);
}
