import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from 'react-i18next';
import Button from "../../components/input/Button";
import Modal from "../../components/layout/Modal";

export function useCookieModal(props) {
    const [key, setKey] = useState(Date.now());
    const [title, setTitle] = useState("");
    const [visible, setVisible] = useState(false);
    const [{ resolve, reject }, setPromise] = useState({});

    const showPrompt = ({ title }) => {
        const promise = new Promise((resolve, reject) => {
            setPromise({ resolve, reject });
        });

        promise.finally(() => {
            setVisible(false);
            setPromise({});
        }).catch((err) => { });

        setTitle(title || '');
        setVisible(true);
        setKey(Date.now());
        return promise;
    }

    const render = () => <CookieModal key={key} visible={visible} resolve={resolve} reject={reject} title={title} />;

    return { showPrompt, render };
}

export function CookieModal({ title, visible, resolve, reject }) {
    const { t } = useTranslation();

    return <Modal title={title} visible={visible} setVisible={() => reject("User cancelled the prompt")} className="text-sm text-primary-text" width="w-full md:w-1/3">
        <p>{t('This website uses cookies to enhance the user experience.')}</p>
        <div className="text-gray-600 flex gap-x-2 justify-center text-lg">
            <a
                className="text-gray-600 underline"
                target="_blank"
                href="https://letterpen.ch/datenschutz.html"
            >
                Datenschutzerklärung
            </a>{' '}
            <div>•</div>
            <a
                className="text-gray-600 underline"
                target="_blank"
                href="https://letterpen.ch/impressum.html"
            >
                Impressum
            </a>
        </div>
        <div className="grid grid-cols-2 gap-x-2 text-xs">
            <Button
                onClick={() => resolve()}
            >
                {t('Accept all cookies')}
            </Button>
            <Button
                onClick={() => reject(t('Accept only necessary cookies'))}
            >
                {t('Accept only necessary cookies')}
            </Button>
        </div>
    </Modal>;
}