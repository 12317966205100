import React, { useState } from 'react';
import clsx from 'clsx';

export default function Modal(props) {
    return <div className={"overflow-y-auto overflow-x-hidden fixed left-0 top-0 z-50 justify-center flex items-center h-full inset-0" + (!props.visible ? ' hidden' : '')}>
        <div className='absolute top-0 left-0 w-full h-full bg-gray-800/75' onClick={(e) => props.setVisible(false)}></div>
        <div className={clsx("relative lg:px-4 lg:max-w-6xl h-full md:h-auto", props.width || 'w-full')}>
            {/* <!-- Modal content --> */}
            <div className="relative bg-surface shadow">
                {/* <!-- Modal header --> */}
                <div className="flex justify-between items-center p-3 pl-6 border-b border-surface-focused bg-surface-hovered">
                    <h3 className="text-lg text-primary-text font-semibold">
                        {props.title}
                    </h3>
                    <button type="button" className="text-primary-text bg-transparent hover:bg-primary-text/25 hover:text-gray-900 text-sm p-1.5 ml-auto inline-flex items-center" onClick={(e) => props.setVisible(false)}>
                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    </button>
                </div>
                {/* <!-- Modal body --> */}
                <div className={"p-6 space-y-6" + (props.className ? ' ' + props.className : '')}>
                    {props.children}
                </div>
            </div>
        </div>
    </div>;
}