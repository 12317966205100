import { useEffect } from "react";

export default function useLeadForensics(id, enableTracking, cookiesAllowed) {
  useEffect(() => {
    if (id && enableTracking && cookiesAllowed) {
      if (!document.getElementById('forensics')) {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = `https://secure.agile-company-365.com/js/${id}.js`;
        script.id = "forensics";

        document.head.appendChild(script);
      }
    }
  }, [id, enableTracking, cookiesAllowed]);
}
