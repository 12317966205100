import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAccount } from '../../context/account.context';
import { useTracking } from '../../context/tracking.context';
import { getTotalProductPrice } from '../../helpers/product.helpers';

const useETracker = ((enabled, secureCode, cookiesAllowed = false) => {
    const location = useLocation();
    const [userData, _] = useAccount();
    const [trackingData, setTrackingData] = useTracking();

    useEffect(() => {
        const [_, section, additional] = location.pathname.split('/');

        let et_pagename = "";
        let et_areas = "";

        let et_basket = "";
        let et_tonr = "";
        let et_tsale = "0";
        let et_tval = "0";

        //Area tracking
        if (section) {
            et_pagename = section;
            if (additional) {
                et_areas = additional;
            }
            et_areas = "";
        }

        //Cart tracking
        if (trackingData) {
            const { template, product, orderData } = trackingData;
            if (orderData && template && product) {
                //https://www.etracker.com/en/docs/integration-setup-2/tracking-code-sdks/tracking-code-integration/setting-parameters/
                const quantity = orderData.quantity || 0;
                const totalPrice = getTotalProductPrice(product.prices, quantity);

                const basket = {
                    ArtNr: orderData.swellProductId,
                    ArtName: template.name,
                    ArtGroup: product.name,
                    Number: quantity,
                    Price: totalPrice,
                }
                et_basket = `${basket.ArtNr},${basket.ArtName},${basket.ArtGroup},${basket.Number},${basket.Price}`;
                et_tonr = orderData.uuid;
                et_tsale = "0";
                if (orderData.finished) {
                    et_tval = `${basket.Price}`;
                } else {
                    et_tval = "0";
                }
            } else {
                // window.et_basket = "";
                // window.et_tonr = "";
                // window.et_tsale = "0";
                // window.et_tval = "0";
            }
        }

        if (userData) {
            //also track some user data?
        }

        const sendOutTrackingData = () => {
            if (window.et_eC_Wrapper)
                window.et_eC_Wrapper({ et_et: secureCode, et_pagename, et_areas, et_basket, et_tonr, et_tsale, et_tval });
        };

        if (!document.getElementById('_etLoader')) {
            if (enabled) {
                const script = document.createElement('script');

                script.src = '//code.etracker.com/code/e.js';
                script.async = true;
                script.id = "_etLoader";
                script.type = 'text/javascript';
                script.setAttribute("data-block-cookies", (!cookiesAllowed).toString());
                script.setAttribute("data-respect-dnt", "true");
                script.setAttribute("data-secure-code", secureCode);

                script.onload = () => {
                    sendOutTrackingData();
                };

                document.head.appendChild(script);
            }
        } else {
            sendOutTrackingData();
        }
    }, [secureCode, cookiesAllowed, location.pathname, trackingData]);
});

export default useETracker;
