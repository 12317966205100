import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import ReactPixel from "react-facebook-pixel";

export default function useFbPixel(id, enableTracking, cookiesAllowed) {
    const [pixelInitialized, setPixelInitialized] = useState(null)
    const location = useLocation();

    useEffect(() => {
        if (id && enableTracking && !pixelInitialized) {
            ReactPixel.init(process.env.REACT_APP_PIXEL_ID);
            setPixelInitialized(true);
        }

        if (cookiesAllowed === false || enableTracking === false) {
            return;
        }

        ReactPixel.pageView()
    }, [cookiesAllowed, enableTracking, location.pathname, pixelInitialized, id]);
}
