import clsx from 'clsx';
import React from "react"

function Button({ children, className, backgroundColor, backgroundColorHover, backgroundColorFocus, border, borderColor, textColor, disabled, ...props }) {
    //bg-surface w-full border-solid border-2 border-accent-selected text-accent-selected font-semibold py-2 px-4 rounded uppercase
    return <button {...props} disabled={disabled} className={clsx(`h-full`, border && `border-solid border-2 border-${borderColor}`, `bg-${backgroundColor}`, (backgroundColorHover && !disabled) && `hover:bg-${backgroundColorHover}`, (backgroundColorFocus && !disabled) && `focus:bg-${backgroundColorFocus}`, `transition-colors`, `text-${textColor}`, `py-2`, `px-4`, `shadow-md`, `uppercase montserrat`, disabled && 'opacity-50', className)}>
        {children}
    </button>;
}

Button.defaultProps = {
    backgroundColor: "accent",
    backgroundColorHover: "accent-hovered",
    backgroundColorFocus: "accent",
    borderColor: "accent",
    textColor: "white",
    border: false,
    type: 'button',
    className: ''
}

export default Button;