export function GetOption(product, option) {
  return product?.options[
    Object.keys(product.options).find(
      (key) => product.options[key].name == option
    )
  ]?.value;
}
export function GetShippingByDescription(shippingRates, description) {
  const ret =
    shippingRates[
    Object.keys(shippingRates).find(
      (key) => shippingRates[key].description == description
    )
    ];
  return ret;
}
export const lastItem = (loc_array) => {
  return loc_array[loc_array.length - 1];
};
export const fixSwellPrices = (prices) => {
  //This is a Swell bug, we should ask them to fix it!
  if (!prices) return [];
  return prices.map((price) => {
    return {
      price: price.price,
      quantity_min: price.quantity_min,
      quantity_max: price.quantity_max || price[Object.keys(price)[0]]?.quantity_max
    }
  });
}

export const getTotalProductPrice = (prices, quantityFiltered) => {
  const pricesSorted = fixSwellPrices(prices).sort((a, b) =>
    a.quantity_min > b.quantity_min
      ? 1
      : b.quantity_min > a.quantity_min
        ? -1
        : 0
  );
  const priceObj = pricesSorted.find((priceObj) => {
    return priceObj.quantity_max >= quantityFiltered;
  }) || pricesSorted[pricesSorted.length - 1] || { price: 0 };

  const pricePerItem = pricesSorted
    ? priceObj.price
    : 0;

  const priceTotal = quantityFiltered * pricePerItem;
  return priceTotal;
}